// @flow
import * as React from 'react';
import { Col, Row} from "react-bootstrap";
import {ColorsChart} from "../components/ColorsChart";
import toImg from 'react-svg-to-image';
import Elevator from "../components/Elevator";
import {RootState, useAppDispatch} from "../store/store";

import {useSelector} from "react-redux";
import {useRef, useState} from "react";
import axios from "axios";
import {convertToPng} from "../store/colorActions";
import {setIsConvertLoading} from "../store/colorSlice";

export const Simulator = () => {
    const dispatch = useAppDispatch()
    const {currentWallsName, currentFloorName} = useSelector((state:RootState) => state.colorReducer)
    const {token} = useSelector((state:RootState) => state.userReducer)
    const [key, setKey] = useState(0)
    const svg = useRef(null)
    const onPrintBtnClick = () => {

            toImg('svg', 'export', {
                format: 'png',
                download: true,
                ignore: null
            }).then(fileData => {
                axios.post("/api/png/convert",{
                    content: fileData,
                    wall: currentWallsName,
                    floor:currentFloorName
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    responseType: 'blob'
                })
                    .then(response =>  {
                        // Create a blob URL from the response data
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                        // Create a link element to trigger the download
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.setAttribute('download', 'export.png');
                        document.body.appendChild(link);
                        // Trigger the click event on the link to start the download
                        link.click();
                        // Remove the link from the DOM
                        document.body.removeChild(link);
                        // Return success
                        dispatch(setIsConvertLoading(false))
                    })
                    .catch(error => {
                        dispatch(setIsConvertLoading(false))
                        console.error(error)
                    });
            })
        setKey(key + 1)
    }



    return (
        <>
            <Row>
                <Col className={"d-flex justify-content-center pt-3"} lg={6}>
                    <Elevator key={key} ref={svg}/>
                </Col>
                <Col lg={6}>
                    <ColorsChart onPrintClick={() => onPrintBtnClick()}/>
                </Col>
            </Row>
        </>
    );
};